<template>
  <!--      Modal Actual -->
  <b-modal
    id="deposit-details-modal"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    :title="modalTitle"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <section>
        <b-card
          id="invoice-preview-card"
          no-body
          class="invoice-preview-card"
        >
          <b-card-body
            class="invoice m-2"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="pt-1"
              >
                <div>
                  <table>
                    <tbody>
                      <tr
                        style="margin-bottom:3px;"
                      >
                        <td
                          v-if="type === 'rider'"
                          class="pr-1"
                        >
                          Total Number of Rider Deposits:
                        </td>
                        <td
                          v-if="type === 'agent'"
                          class="pr-1"
                        >
                          Total Number of Agent Deposits:
                        </td>
                        <td class="w-50 pl-2 text-right">
                          <span>{{ depositData.count }}</span>
                        </td>
                      </tr>
                      <tr
                        style="margin-bottom:3px;"
                      >
                        <td
                          v-if="type === 'rider'"
                          class="pr-1"
                        >
                          Total Rider Commissions:
                        </td>
                        <td
                          v-if="type === 'agent'"
                          class="pr-1"
                        >
                          Total Agent Commissions:
                        </td>
                        <td class="w-50 pl-2 text-right">
                          <span>{{ __numberWithCommas(depositData.total_commission, true) }}</span>
                        </td>
                      </tr>
                      <div style="height:2px;" />
                      <tr>
                        <td class="pr-1">
                          <h6 class="font-weight-bolder">
                            NET Payable:
                          </h6>
                        </td>
                        <td class="w-50 pl-2 text-right">
                          <span class="font-weight-bolder">{{ __numberWithCommas(depositData.total_commission, true) }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="4"
        >
          <!-- Button: Confirm -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-success"
            class="mb-75"
            block
            @click="onSubmit"
          >
            Pay
          </b-button>
        </b-col>
      </section>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BCol,
  BCardBody,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    selectedDeposits: [],
    submitFunction: () => {},
    deposit: {},
    type: null,
    depositData: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BModal,
    BCol,
    BCardBody,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.type === 'rider' ? 'Rider Commission Payment Details' : 'Agent Commission Payment Details'
    },
  },
  async created() {
    this.id = this.$route.params.id
    this.businessSettings = JSON.parse(localStorage.getItem('businessSettings'))
    this.companyCurrency = this.businessSettings.company_currency
  },
  methods: {
    async openModal(selectedDeposits, submitFunction, deposit, type) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.selectedDeposits = selectedDeposits
      this.submitFunction = submitFunction
      this.deposit = deposit
      this.type = type
      this.getDepositDetails()
    },
    async getDepositDetails() {
      this.modalLoading = true
      try {
        const payload = {
          ref_numbers: this.selectedDeposits,
        }
        let data
        if (this.type === 'rider') {
          data = (await FinanceRepository.getRiderCommissionDetailsBeforeSubmit(payload)).data.data
        } else if (this.type === 'agent') {
          data = (await FinanceRepository.getAgentCommissionDetailsBeforeSubmit(payload)).data.data
        }
        this.depositData = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async onSubmit() {
      try {
        this.modalLoading = true
        await this.submitFunction(this.deposit)
        this.open = false
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
